.button {
  display: flex;
  min-width: 56px;
  min-height: 56px;
  align-items: center;
  justify-content: center;
  padding: var(--ds-space-1-x);
  border: 0;
  margin-left: var(--ds-space-1-x);
  background: none;
  border-radius: 15px;
  color: var(--ds-color-pureWhite);
  cursor: pointer;
  fill: var(--ds-color-pureWhite);
  transition: all 0.2s ease-in-out;

  @media (--m) {
    display: none;
  }

  @media (hover: hover) {
    &:hover {
      background-color: var(--ds-color-pureWhite);
      color: var(--ds-color-deepPurple);
      fill: var(--ds-color-deepPurple);
    }
  }
}
