.container {
  position: relative;
}

.sharedUiHeader {
  position: relative;
  z-index: 2;
  display: grid;
  max-width: 80rem;
  min-height: 5.375rem;
  padding: 0 1rem;
  margin-right: auto;
  margin-left: auto;
  color: #fff;
  grid-template-columns: 1fr 1fr;
  line-height: 1.5rem;

  @media (--s) {
    grid-template-columns: 1fr 2fr 1fr;
  }

  @media (--m) {
    padding: 0 5rem;
  }
}

.dropdownModal {
  display: none;

  @media (--s) {
    position: absolute;
    z-index: 2;
    top: 5.125rem;
    display: flex;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: rgb(0 0 0 / 50%);

    @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      backdrop-filter: blur(10px);
    }
  }
}
