.container {
  position: relative;
}

.greyBottomBorder {
  border-bottom: 1px solid rgb(255 255 255 / 20%);
}

.inner {
  display: flex;
  max-width: var(--ds-dimensions-max);
  min-height: 82px;
  justify-content: flex-start;
  padding-right: var(--ds-space-2-x);
  padding-left: var(--ds-space-2-x);
  margin-right: auto;
  margin-left: auto;

  @media (--l) {
    padding-right: var(--ds-space-4-x);
    padding-left: var(--ds-space-4-x);
  }
}

.inner > a {
  flex-shrink: 0;
  align-self: center;
}
