.menuList {
  display: flex;
  flex-direction: column;
  padding: var(--ds-space-5-x) var(--ds-space-3-x) 0;

  @media (--m) {
    max-width: 1024px;
    flex-direction: row;
    justify-content: space-between;
    padding: 80px var(--ds-space-3-x) var(--ds-space-12-x);
    margin: 0 auto;
  }
}
