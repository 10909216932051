.dropdownModal {
  display: none;

  @media (--s) {
    position: absolute;
    z-index: 2;
    top: 5.125rem;
    display: flex;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: rgb(0 0 0 / 50%);

    @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      backdrop-filter: blur(10px);
    }
  }
}
