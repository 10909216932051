.menuListSection {
  margin-bottom: var(--ds-space-6-x);

  @media (--m) {
    margin-bottom: 0;
  }
}

.menuListSection li:last-of-type {
  margin-bottom: 0;
}

.title {
  display: block;
  margin-bottom: var(--ds-space-4-x);
  color: var(--ds-color-vividViolet);
  font-size: var(--ds-space-2-x);
  line-height: 1.55;
}
