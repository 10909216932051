.mobileMenuHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  color: #8c32be;
}

.mobileProfile {
  margin-right: 1rem;
}

.mobileCloseButton {
  border: 0;
  background-color: transparent;
}
