.container {
  display: none;
  margin: 0;

  @media (--m) {
    display: flex;
  }
}

.nav {
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
}

.navItem::before {
  position: absolute;
}

.navItem > a {
  display: flex;
  height: 100%;
  align-items: center;
}

.navItem svg {
  margin-left: var(--ds-space-1-x);
}

.navItem:hover a {
  background-color: var(--ds-color-pureWhite);
}

.navItem:hover a > span {
  color: var(--ds-color-deepPurple);
}

.vehicleCount {
  display: block;
  margin-left: var(--ds-space-2-x);

  @media (--m) {
    align-self: flex-end;
    font-size: var(--ds-font-size-s);
  }
}

.menuArticle {
  display: 'flex';
  flex-direction: 'column';
}
