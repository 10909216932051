.userProfileWrapper {
  display: none;

  @media (--s) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.profileLink {
  display: flex;
  align-items: center;
}

.profileLinkText {
  padding-right: 0.75rem;
  font-weight: 600;
}
