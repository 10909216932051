.navLink {
  position: relative;
  text-decoration: none !important;
  white-space: nowrap;
}

.navLinkInner {
  margin: var(--ds-space-2-x) var(--ds-space-3-x);
  font-size: var(--ds-font-size-xs);

  @media (--m) {
    margin: var(--ds-space-2-x);
  }

  @media (--l) {
    margin: var(--ds-space-2-x) var(--ds-space-3-x);
    font-size: var(--ds-font-size-s);
  }
}
