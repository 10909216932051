/* stylelint-disable scale-unlimited/declaration-strict-value */
.listItem {
  padding-bottom: 1rem;
  color: #311b77;
  font-size: 1rem;
  line-height: 1.5rem;
}

.horizontalLine {
  border: 0.063rem solid #f8f6ff;
}
