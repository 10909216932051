.articleContainer {
  max-width: 575px;
  color: var(--ds-color-mineShaft);
}

.articleContainer.slimContainer {
  max-width: 480px;
}

.articleSectionHeading {
  margin-bottom: var(--ds-space-3-x);
  color: var(--ds-color-vividViolet);
  font-size: var(--ds-space-2-x);
}

.articleContainerInner {
  display: flex;
}

.articleImg {
  max-width: 193px;
  max-height: 122px;
  margin-right: var(--ds-space-3-x);
  border-radius: var(--ds-space-1-x);
  object-fit: cover;
}

.articleTitle {
  margin-bottom: var(--ds-space-1-x);
}

.articleBody {
  margin-bottom: var(--ds-space-2-x);
}

.articleLink,
.articleLink:link,
.articleLink:hover,
.articleLink:visited {
  display: flex;
  align-items: center;
  color: var(--ds-color-toreaBay);
  font-size: var(--ds-font-size-m);
}

.articleLink:hover {
  text-decoration: underline;
}

.articleLink svg {
  margin-left: var(--ds-space-1-x);
}
