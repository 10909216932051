/* stylelint-disable scale-unlimited/declaration-strict-value */
.navCard {
  border: 0.063rem solid #e1dafb;
  background-color: #f8f6ff;
  border-radius: 1rem;
}

.headingContainer {
  width: 16.375rem;
  padding: 1.5rem 1.625rem 0 1.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.heading {
  padding-bottom: 0.5rem;
  color: #8f1dbf;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.625rem;
}

.subHeading {
  padding-bottom: 1rem;
  color: #2f2f2f;
}

.link {
  display: flex;
  align-items: center;
  padding: 1.125rem 1.625rem 1.375rem 1.5rem;
  color: #1565c0;
}

.icon {
  padding-top: 0.75rem;
  padding-left: 0.75rem;
}

.imageWrapper {
  padding: 0 1.625rem 0 1.5rem;
}

.image {
  border-radius: 0.5rem;
}
