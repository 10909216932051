/* stylelint-disable scale-unlimited/declaration-strict-value */
@value variables: '../../../variables.css';
@value layout-container-border-radius from variables;

.headerContainer {
  background: linear-gradient(
    166deg,
    var(--ds-color-deepPurple),
    var(--ds-color-pearlescentPink)
  );
}

.borderRadiusHeader {
  padding-bottom: layout-container-border-radius;
}

.verticalGradient {
  background: linear-gradient(180deg, #311b77, #8f1dbf 60%, #d333ec);
}

.borderRadiusMain {
  border-top-left-radius: layout-container-border-radius;
  /* stylelint-disable function-calc-no-unspaced-operator */
  margin-top: calc(layout-container-border-radius * -1);
  border: 1px var(--ds-color-pureWhite) solid;
  background: var(--ds-color-pureWhite);
}

.headerHasBleed {
  background: none;
  padding-bottom: 0;
  z-index: 2;
}

.v2Modal {
  @media (--m) {
    background-color: white;
    width: 15.625rem;
    height: 17.5rem;
    border-radius: 1.25rem;
    position: absolute;
    margin-top: 0.5rem;
    right: 2rem;
    top: 5.125rem;
  }
}

.v2ModalHeading {
  color: black;
  text-align: center;
}
