.backdrop {
  position: fixed;
  z-index: 5;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  background-color: rgba(47 47 47 / 80%);
}

.inner {
  position: absolute;
  z-index: 6;
  top: 0;
  right: 0;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background: var(--ds-color-pureWhite);
  overflow-y: auto;

  @media (--s) {
    max-width: 384px;
  }
}

.menuItemButton {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  border: none;
  background: none;
  color: var(--ds-color-deepPurple);
  cursor: pointer;
}

.vehicleCount {
  font-size: var(--ds-font-size-m);
}

.containerEnter {
  transform: translateX(100%);
}

.containerEnterActive {
  transform: translateX(0);
  transition: transform 300ms ease-in-out;
}

.containerExit {
  transform: translateX(0);
}

.containerExitActive {
  transform: translateX(100%);
  transition: transform 300ms ease-in-out;
}

.menuContainer {
  position: relative;
  height: 100%;
  overflow-x: hidden;
}

.menu {
  position: absolute;
  right: 0;
  left: 0;
  width: 100vw;
  transition: transform 300ms ease-in-out;

  @media (--s) {
    width: auto;
  }
}

/* Primary */

.primaryMenu {
  transform: translateX(0);
}

.menuPrimaryEnter {
  transform: translateX(-100%);
}

.menuPrimaryEnterDone {
  transform: translateX(0);
}

.menuPrimaryExit {
  transform: translateX(0);
}

.menuPrimaryExitDone {
  transform: translateX(-100%);
}

/* Secondary */

.secondaryMenu {
  transform: translateX(100%);
}

.menuSecondaryEnter {
  transform: translateX(100%);
}

.menuSecondaryEnterDone {
  transform: translateX(0);
}

.menuSecondaryExit {
  transform: translateX(0);
}

.menuSecondaryExitDone {
  transform: translateX(100%);
}

.newsAndReviews.newsAndReviews {
  margin-bottom: var(--ds-space-4-x);
}
