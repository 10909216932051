.listWrapperContainer {
  padding-left: 1rem;
  color: #8f1dbf;
  font-size: 1.25rem;
  line-height: 1.5rem;

  @media (--s) {
    height: auto;
    padding-left: 0;
    font-size: 1rem;
  }
}

.heading {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 1.25rem;
  font-weight: 700;

  @media (--s) {
    font-size: 1rem;
  }
}

.wrapperLink {
  display: flex;
  align-items: center;
  padding-top: 1rem;
  color: #1565c0;
  text-align: center;
}

.wrapperLinkIcon {
  display: flex;
  align-items: center;
  padding-left: 1rem;
}
