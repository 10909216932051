.menuIconWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (--s) {
    display: none;
  }
}

.menuIconWrapper > button {
  border: 0;
  background-color: transparent;
  color: #fff;
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  min-height: 3.5rem;
  min-width: 3.5rem;
  padding: 0.5rem;
}

.mobileMenuWrapper {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  color: black;
}
