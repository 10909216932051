.svg {
  margin-right: 18px;
}

.link {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.link:hover span {
  text-decoration: underline;
}

.link:hover span:nth-of-type(2) {
  text-decoration: none;
}

.linkWithIcon,
.linkWithIcon:link,
.linkWithIcon:visited {
  color: var(--ds-color-toreaBay);
  font-size: var(--ds-font-size-m);
  text-decoration: underline;
  text-underline-position: under;
}

.smallText {
  font-size: var(--ds-font-size-m);
}
