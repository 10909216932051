@import 'reset-css';
@import 'normalize.css';

:root {
  --font-family: 'Buenos Aires', Avenir, Futura, Arial, SansSerif, sans-serif;
  --link-colour: #00f;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: initial;
  font-size: initial;
}

html,
body {
  min-height: 100vh;
  box-sizing: border-box;
  font-family: 'Buenos Aires', Avenir, Futura, Arial, SansSerif, sans-serif;
  font-size: 16px;
  font-variant-ligatures: none;
  /* stylelint-disable-next-line */
  background-image: #fff;
  -webkit-font-smoothing: antialiased;
}

*,
*::before,
*::after {
  max-width: 100%;
  box-sizing: inherit;
}

a {
  text-decoration: none;
  /* stylelint-disable-next-line */
  color: inherit;
}

a[target='_blank']:not(.storyblokRichTextBody a[target='_blank']) {
  white-space: nowrap;
}

@font-face {
  font-display: swap;
  font-family: 'Buenos Aires';
  font-stretch: normal;
  font-style: normal;
  font-weight: 800;
  src: url('/fonts/BuenosAiresWeb-Black.eot');
  src: url('/fonts/BuenosAiresWeb-Black.eot?#iefix') format('embedded-opentype'),
    url('/fonts/BuenosAiresWeb-Black.woff2') format('woff2'),
    url('/fonts/BuenosAiresWeb-Black.woff') format('woff');
  unicode-range: U+000D-FB04;
}

@font-face {
  font-display: swap;
  font-family: 'Buenos Aires';
  font-stretch: normal;
  font-style: italic;
  font-weight: 800;
  src: url('/fonts/BuenosAiresWeb-BlackItalic.eot');
  src: url('/fonts/BuenosAiresWeb-BlackItalic.eot?#iefix')
      format('embedded-opentype'),
    url('/fonts/BuenosAiresWeb-BlackItalic.woff2') format('woff2'),
    url('/fonts/BuenosAiresWeb-BlackItalic.woff') format('woff');
  unicode-range: U+000D-FB04;
}

@font-face {
  font-display: swap;
  font-family: 'Buenos Aires';
  font-stretch: normal;
  font-style: normal;
  font-weight: var(--ds-font-weight-semibold);
  src: url('/fonts/BuenosAiresWeb-SemiBold.eot');
  src: url('/fonts/BuenosAiresWeb-SemiBold.eot?#iefix')
      format('embedded-opentype'),
    url('/fonts/BuenosAiresWeb-SemiBold.woff2') format('woff2'),
    url('/fonts/BuenosAiresWeb-SemiBold.woff') format('woff');
  unicode-range: U+000D-FB04;
}

@font-face {
  font-display: swap;
  font-family: 'Buenos Aires';
  font-stretch: normal;
  font-style: normal;
  font-weight: var(--ds-font-weight-bold);
  src: url('/fonts/BuenosAiresWeb-Bold.eot');
  src: url('/fonts/BuenosAiresWeb-Bold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/BuenosAiresWeb-Bold.woff2') format('woff2'),
    url('/fonts/BuenosAiresWeb-Bold.woff') format('woff');
  unicode-range: U+000D-FB04;
}

@font-face {
  font-display: swap;
  font-family: 'Buenos Aires';
  font-stretch: normal;
  font-style: italic;
  font-weight: var(--ds-font-weight-bold);
  src: url('/fonts/BuenosAiresWeb-BoldItalic.eot');
  src: url('/fonts/BuenosAiresWeb-BoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('/fonts/BuenosAiresWeb-BoldItalic.woff2') format('woff2'),
    url('/fonts/BuenosAiresWeb-BoldItalic.woff') format('woff');
  unicode-range: U+000D-FB04;
}

@font-face {
  font-display: swap;
  font-family: 'Buenos Aires';
  font-stretch: normal;
  font-style: normal;
  font-weight: var(--ds-font-weight-medium);
  src: url('/fonts/BuenosAiresWeb-Regular.eot');
  src: url('/fonts/BuenosAiresWeb-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('/fonts/BuenosAiresWeb-Regular.woff2') format('woff2'),
    url('/fonts/BuenosAiresWeb-Regular.woff') format('woff');
  unicode-range: U+000D-FB04;
}

@font-face {
  font-display: swap;
  font-family: 'Buenos Aires';
  font-stretch: normal;
  font-style: italic;
  font-weight: var(--ds-font-weight-medium);
  src: url('/fonts/BuenosAiresWeb-RegularItalic.eot');
  src: url('/fonts/BuenosAiresWeb-RegularItalic.eot?#iefix')
      format('embedded-opentype'),
    url('/fonts/BuenosAiresWeb-RegularItalic.woff2') format('woff2'),
    url('/fonts/BuenosAiresWeb-RegularItalic.woff') format('woff');
  unicode-range: U+000D-FB04;
}

@media (prefers-reduced-motion) {
  /* stylelint-disable-next-line selector-class-pattern */
  .react-slidedown {
    transition-duration: 0s;
  }
}

.internalLinkList {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.internalLinkList :nth-child(n + 2) {
  padding-top: 1rem;
}

.internalLink {
  color: var(--link-colour);
  text-decoration: underline;
}
