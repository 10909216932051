.svg {
  display: flex;
  width: 1.25rem;
  height: 1.25rem;
  align-items: center;
  justify-content: center;
  fill: #fff;
}

.link {
  position: relative;
  display: flex;
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border: 0.188rem solid #57e8ff;
  border-radius: 50%;
  margin-left: 0.5rem;
}

.link:hover {
  background-color: #ffffff;
}

.link:hover svg {
  color: #311c77;
  fill: #311c77;
}

.number {
  position: absolute;
  z-index: 1;
  top: -0.5rem;
  right: -0.625rem;
  display: flex;
  width: 1.125rem;
  height: 1.125rem;
  align-items: center;
  justify-content: center;
  background: linear-gradient(96.3deg, #57e8ff -9.86%, #8dffbe 98.82%);
  border-radius: 50%;
  color: #311c77;
  font-size: 0.875rem;
  text-align: center;
}

.pulse {
  animation: pulsing 0.8s;
  animation-timing-function: ease-in-out;
}

@keyframes pulsing {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.5);
  }

  75% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}
