/* stylelint-disable scale-unlimited/declaration-strict-value */
.basketContainer {
  position: relative;
  display: flex;
  padding: 0 0.5rem;
  border: 0;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
}

.basketIconsContainer {
  position: relative;
  display: flex;
  justify-content: center;
}

.basketContainer:hover > .basketIconsContainer > .basketIcon {
  background-color: white;
  border-radius: 2rem;
  color: #311b77;
}

.arrowBorder {
  z-index: 1;
  display: flex;
  max-width: 2.625rem;
  max-height: 1.875rem;
  align-items: center;
  justify-content: center;
}

.basketIcon {
  position: absolute;
  z-index: 1;
  top: 0.188rem;
  left: 0.188rem;
  display: flex;
  width: 2.25rem;
  height: 1.625rem;
  align-items: center;
  justify-content: center;
}

.basketIcon svg {
  width: 1.15rem;
  height: 1.2rem;
}

.number,
.timerIcon {
  position: absolute;
  z-index: 1;
  top: -0.438rem;
  right: 0.063rem;
  display: flex;
  width: 1.125rem;
  height: 1.125rem;
  align-items: center;
  justify-content: center;
  animation: pulsing 0.8s ease-in-out;
  border-radius: 50%;
}

.number {
  background: linear-gradient(96.3deg, #57e8ff -9.86%, #8dffbe 98.82%);
  color: #311c77;
  font-size: 0.875rem;
  text-align: center;
}

.timerIcon {
  padding: 0.25rem;
  border: solid 0.063rem #fafafa;
  background: linear-gradient(#ff2dff -9.86%, #f3755d 98.82%);
  border-radius: 1.25rem;
  color: #fafafa;
}

.pulse {
  animation: pulsing 0.8s ease-in-out;
}

@keyframes pulsing {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.5);
  }

  75% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}
