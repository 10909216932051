.svg {
  display: flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  fill: #fff;
}

.link {
  position: relative;
  display: flex;
  width: 39px;
  height: 39px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border: 4px solid var(--ds-color-iceBlue);
  border-radius: 50%;
  color: var(--ds-color-iceBlue);

  @media (hover: hover) {
    &:hover {
      background-color: var(--ds-color-pureWhite);

      svg {
        color: var(--ds-color-deepPurple);
        fill: var(--ds-color-deepPurple);
      }
    }
  }
}

.number {
  position: absolute;
  z-index: 1;
  top: -8px;
  right: -10px;
  display: flex;
  width: 18px;
  height: 18px;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    96.3deg,
    var(--ds-color-iceBlue) -9.86%,
    var(--ds-color-goGreen) 98.82%
  );
  border-radius: 50%;
  color: var(--ds-color-deepPurple);
  font-size: 14px;
  text-align: center;
}

.pulse {
  animation: pulsing 0.8s;
  animation-timing-function: ease-in-out;
}

@keyframes pulsing {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.5);
  }

  75% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}
