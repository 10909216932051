.footer {
  padding-top: var(--ds-space-4-x);
  padding-bottom: var(--ds-space-7-x);
  background-color: var(--ds-color-deepPurple);
  border-top-left-radius: 30px;
  color: var(--ds-color-pampas);
  text-align: center;

  @media (--m) {
    padding-top: var(--ds-space-8-x);
    padding-bottom: calc(var(--ds-space-8-x) + var(--ds-space-2-x));
  }
}

.footerContainer {
  overflow: hidden;
  max-width: var(--ds-dimensions-max);
  padding-right: var(--ds-space-2-x);
  padding-left: var(--ds-space-2-x);
  margin-right: auto;
  margin-left: auto;

  @media (--m) {
    padding-right: var(--ds-space-4-x);
    padding-left: var(--ds-space-4-x);
  }
}

.heading {
  margin-bottom: var(--ds-space-4-x);
  color: var(--ds-color-pampas);

  @media (--m) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.subHeading {
  display: block;
  margin-top: var(--ds-space-3-x);
  margin-bottom: var(--ds-space-3-x);

  @media (--m) {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: var(--ds-space-1-and-a-half-x);
    font-size: var(--ds-font-size-m);
  }
}

.body {
  max-width: 90ch;
  margin-right: auto;
  margin-bottom: var(--ds-space-3-x);
  margin-left: auto;
  color: var(--components-footer-base-color);
  opacity: 0.6;

  &:last-child {
    margin-bottom: 0;
  }

  @media (--m) {
    margin-bottom: var(--ds-space-4-x);
  }
}

.noBorderRadius {
  border-top-left-radius: 0;
}

.appStoreButtons {
  display: flex;
  justify-content: center;
  padding-bottom: var(--cds-spacing-large-rem-200);
  gap: var(--cds-spacing-medium-rem-100);

  @media (--m) {
    padding-bottom: var(--cds-spacing-large-rem-100);
  }
}
