.navButtonInner {
  margin: var(--ds-space-2-x) var(--ds-space-3-x);
  font-size: var(--ds-font-size-xs);

  @media (--m) {
    margin: var(--ds-space-2-x);
  }

  @media (--l) {
    margin: var(--ds-space-2-x) var(--ds-space-3-x);
    font-size: var(--ds-font-size-s);
  }
}

button.navButton:focus {
  outline: revert;
}

.navButton {
  width: 100%;
  height: 100%;
  padding: 0;
  border: none;
  background-color: var(--ds-color-transparent);
  color: var(--ds-color-pureWhite);
  cursor: pointer;
  font-weight: var(--ds-font-weight-semibold);
}

.navButton > span {
  white-space: nowrap;
}

.navButton:hover {
  background-color: var(--ds-color-pureWhite);
  color: var(--ds-color-deepPurple);
}

.navButton:hover .navButtonInner > svg {
  fill: var(--ds-color-deepPurple);
}

.chevronDownIcon {
  width: 13px;
  height: 13px;
  fill: var(--ds-color-pureWhite);
}

.navButtonActive {
  background-color: var(--ds-color-pureWhite);
  color: var(--ds-color-deepPurple);
}

.navButtonActive .chevronDownIcon {
  fill: var(--ds-color-deepPurple);
  transform: rotate(180deg);
}
