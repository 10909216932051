.container {
  display: flex;
  min-height: 73px;
  align-items: center;
  justify-content: space-between;
  padding: var(--ds-space-2-x) var(--ds-space-3-x);
  background: var(--ds-color-pureWhite);
  color: var(--ds-color-deepPurple);
}

.sticky {
  position: sticky;
  top: 0;
  right: 0;
}

.linkContainer {
  display: flex;
}

.link {
  display: flex;
  align-items: center;
  margin-right: var(--ds-space-2-x);
  text-align: center;
}

.closeButton {
  display: flex;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;
  text-align: center;
}

.mainMenuButton {
  padding: 0;
  border: none;
  background: none;
  color: var(--ds-color-deepPurple);
  cursor: pointer;
  font-size: 18px;
  line-height: 26px;
}

.mainMenuIcon {
  margin-right: var(--ds-space-2-x);
}

.boxshadow {
  box-shadow: 0 1px 14px rgba(0 0 0 / 6%);
}
