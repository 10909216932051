.logoLight {
  margin-right: auto;
  margin-bottom: 6px;
}

.logoLight svg {
  fill: var(--ds-color-pampas);
}

.logoDark {
  margin-bottom: 6px;
}

.logoDark svg {
  fill: var(--ds-color-deepPurple);
}

.logoText {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
}
