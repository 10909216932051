.menuListItem {
  display: flex;
  align-items: center;
  margin-bottom: var(--ds-space-4-x);
  color: var(--ds-color-deepPurple);
  font-size: var(--ds-font-size-xl);

  @media (--m) {
    font-size: var(--ds-font-size-l);
  }
}
