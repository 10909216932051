.basketIconContainer,
.basketIconContainerWithTimer {
  position: relative;
  align-self: center;
  padding: 0;
  border: none;
  margin-top: 4px;
  margin-right: 8px;
  background: none;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    margin-left: 6px;
  }
}

/* * counter icon */
.basketIconContainer > :first-child {
  top: -2px;
  right: -5px;
}

.basketIconContainerWithTimer > :first-child {
  z-index: 1;
  top: -3px;
  right: -9px;
}

.basketIconContainer > :nth-child(2),
.basketIconContainerWithTimer > :nth-child(2) {
  position: relative;
  z-index: 1000;
  pointer-events: none;
}

.basketIcon {
  position: absolute;
  top: 0;
  display: flex;
  width: 100%;
  max-width: 47px;
  height: 100%;
  max-height: 32px;
  align-items: center;
  justify-content: center;
  padding-left: 3px;
  margin-top: 5px;
  border-radius: 20px;
  color: var(--ds-color-pampas);

  &:hover {
    background-color: var(--ds-color-pampas);
    color: var(--ds-color-deepPurple);
  }
}

.basketIcon > svg {
  width: 20px;
  height: 16px;
}

.timerIcon {
  position: absolute;
  top: 0;
  right: -5px;
  display: flex;
  width: 24px;
  max-width: 47px;
  height: 24px;
  max-height: 32px;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-color: var(--ds-color-pampas);
  margin-top: 0;
  animation: pulsing 0.8s;
  animation-timing-function: ease-in-out;
  background: linear-gradient(#ff2dff -9.86%, #f3755d 98.82%);
  border-radius: 20px;
  color: var(--ds-color-pampas);
}

.number {
  position: absolute;
  z-index: 1;
  top: -8px;
  right: -10px;
  display: flex;
  width: 18px;
  height: 18px;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    96.3deg,
    var(--ds-color-iceBlue) -9.86%,
    var(--ds-color-goGreen) 98.82%
  );
  border-radius: 50%;
  color: var(--ds-color-deepPurple);
  font-size: 14px;
  text-align: center;
}

.pulse {
  animation: pulsing 0.8s;
  animation-timing-function: ease-in-out;
}

@keyframes pulsing {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.5);
  }

  75% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}
