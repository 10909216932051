.banner {
  position: fixed;
  z-index: 2;
  bottom: 0;
  display: block;
  width: 100%;
  max-height: 500px;
  background: var(--ds-color-pureWhite);
  box-shadow: 0 -11px 22px rgb(169 169 169 / 20%);
  transition: max-height 0.25s ease-in;
}

.bannerSection {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: var(--ds-space-2-x);

  @media (--s) {
    flex-direction: row;
  }
}

.hideBasketBanner {
  max-height: 0;
  transition: max-height 0.25s ease-out;
}

.closeIconButton {
  position: absolute;
  top: var(--ds-space-2-x);
  right: var(--ds-space-2-x);
  display: flex;
  width: var(--ds-space-3-x);
  height: var(--ds-space-3-x);
  align-items: center;
  justify-content: center;
  border: 0;
  background: var(--ds-color-wildSand);
  border-radius: 50%;
  cursor: pointer;
}

.bannerHeading {
  top: var(--ds-space-2-x);
  left: var(--ds-space-2-x);
  color: var(--ds-color-deepPurple);
  font-size: var(--ds-font-size-m);
  font-weight: var(--ds-font-weight-bold);
}

.basketBannerWrapper {
  display: flex;
  max-width: 1024px;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
}

.returnToCheckoutCta {
  flex-shrink: 0;
}
