.button {
  display: none;
  min-width: 56px;
  min-height: 56px;
  flex-shrink: 0;
  align-items: center;
  padding: var(--ds-space-1-x);
  border: 0;
  margin-left: auto;
  background: none;
  color: var(--ds-color-pampas);
  cursor: pointer;

  @media (--m) {
    display: flex;
  }
}

.svg {
  position: relative;
  display: flex;
  width: 39px;
  height: 39px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--ds-color-pampas);

  @media (hover: hover) {
    &:hover {
      background-color: var(--ds-color-pureWhite);
      color: var(--ds-color-deepPurple);
      fill: var(--ds-color-deepPurple);
    }
  }
}

.label {
  margin-right: var(--ds-space-1-x);
  color: var(--ds-color-pampas);
  font-size: 16px;
}
