.navItemContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 1rem;
  border: 0;
  border-bottom: 1px solid #5234b633;
  background-color: transparent;
  color: #311b77;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;

  @media (--s) {
    width: auto;
    color: #fff;
    font-size: 1rem;
  }
}

.navItemContainer:hover,
.navItemContainerActive {
  height: 100%;
  background-color: #fff;
  color: #311b77;
}

.secondaryNavItemContainer {
  justify-content: revert;
}

.mobileChevron {
  display: flex;
  padding: 0 2rem 0 0;
  border: 0;
  background-color: transparent;

  @media (--s) {
    display: none;
  }
}

.desktopChevron {
  display: none;

  @media (--s) {
    display: flex;
    padding-left: 1rem;
  }
}
