.navItemDropdown {
  display: none;

  @media (--s) {
    position: absolute;
    top: 0;
    display: block;
    width: 20rem;
    padding: 3rem;
    background-color: #fff;
    color: #311b77;
  }
}
