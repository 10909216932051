.navDropdown {
  position: absolute;
  z-index: 10;
  top: 100%;
  right: 0;
  left: 0;
  height: 100vh;
}

.navDropdownBG {
  position: absolute;
  z-index: 0;
  background-color: rgb(0 0 0 / 20%);
  inset: 0;

  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    backdrop-filter: blur(10px);
  }
}

.navDropdownInner {
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-color: var(--ds-color-pureWhite);
  border-bottom-left-radius: var(--ds-space-2-x);
}
